import './footer.css';
import TrustedByDesktop from '../../assets/Trusted By updated.jpg';
export const Footer = () => {
    return (
        <footer className='footer_component'>
            <h1 className='footer_component_title'>TRUSTED BY</h1>
            <img className='footer_component_trusted_by' src={TrustedByDesktop} alt="" />
            <h3 className='footer_component_subtitle'>Contact</h3>
            <p className='footer_component_email'>beatsofsync@gmail.com</p>
            <span className='footer_component_copyright'>© {new Date().getFullYear().toString()} by Sync</span>
        </footer>
    )
}