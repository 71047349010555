import { ElfsightWidget } from "react-elfsight-widget";
import './player.css'
import { useEffect } from "react";
import {useMediaQuery} from 'react-responsive'
export const Player = () => {

  const isMobile = useMediaQuery({maxWidth: 768});
  

  useEffect(() => {
    const container = document.querySelector('.player_component') as HTMLDivElement;
    const parent = container.closest('.full_screen_component') as HTMLDivElement;


    if(parent && isMobile)
      {

        const handleScroll = () => {
          // Your logic to handle scroll changes here
          console.log("Scroll position changed:", parent.scrollTop);
        };

        parent.style.minHeight = 'auto';
        parent.style.height = 'auto';
        parent.style.overflowY = 'scroll';
        
        // Calculate the aspect ratio (width / height)
        const aspectRatio = 375.200 / 920.800;

    
        // Set the aspectRatio style property
        parent.style.padding = '5rem 0rem'
        parent.style.aspectRatio = `${aspectRatio}`;
        parent.scrollTop = parent.scrollHeight - parent.clientHeight        
        parent.addEventListener('scroll', handleScroll);

        return () => {
          parent.removeEventListener('scroll', handleScroll);
        };
      }
  }, []);


  useEffect(() => {

  });

  return (

    <>
      <div className="player_component_background"></div>
      <div className="player_component">
        <ElfsightWidget widgetId='4fb2d96f-42ef-44e5-8cc7-7ea0d20bc695' />
      </div>
    </>
  );
}