import LogoTextImage from '../../assets/Logo/logo.svg';
import InstagramIcon from '../../assets/Instagram/icon.svg'
import SpotifyIcon from '../../assets/Spotify/icon.svg'
import LinkedInIcon from '../../assets/LinkedIn/icon.svg'

import './frame1.view.css'
import '../../components/logo-3d/logo3d.css';
import Spline from '@splinetool/react-spline';

export const Frame1: React.FunctionComponent = () => {
    return (
        <div className="frame1_container">
            <div className="frame1_wrapper">
                <p className='frame1_logo_img bebas-neue-regular'>SYNC</p>
                <div className='frame1_social_media_links'>
                    <img onClick={(event) => window.open("https://www.instagram.com/sync.beats/")} style={{ cursor: 'pointer' }} src={InstagramIcon} alt='Instagram Link' />
                    <img onClick={() => window.open("https://open.spotify.com/artist/6h2Ljt4yaJzSN5IGJusE13?si=UNERw9KUS0WfRdBjz9K2Ng")} style={{ cursor: 'pointer' }} src={SpotifyIcon} alt='Spotify Link' />
                    {/* <img onClick={() => window.open("https://www.linkedin.com/company/prodbysync/")} style={{ cursor: 'pointer' }} src={LinkedInIcon} alt='Linkedin Link' /> */}
                </div>
            </div>
            {/* <div id='logo3d_component'> */}
                <Spline style={{ height: '100%', width: '100%' }} className='logo3d_component__element' scene="https://prod.spline.design/jVZ1sTznp0O9hSG5/scene.splinecode" />
            {/* </div> */}
        </div>
    )
}