import { useEffect, useMemo, useRef, useState } from 'react';
import './App.css';
import { Logo3D } from './components/logo-3d/Logo3D';
import { Slider } from './components/slider/Slider';
import { Frame1 } from './view/frame1.view/Frame1.view';
import { Frame2 } from './view/frame2.view/Frame2.view';
import { Player } from './components/player/Player';
import { Frame3 } from './view/frame3.view/Frame3.view';
import { Description } from './components/description/Description';
import { Footer } from './components/footer/Footer';

const App = () => {

  return (
    <div className="App">
      <section id='section_1'>
          <Frame1 />
      </section>
      <section id='section_2'>
        <Frame2>
          <Slider />
        </Frame2>
      </section>
      <section id='section_3'>
        <Frame3>
          <Description />
        </Frame3>
      </section>
      <section id='section_4'>
        <Frame2>
          <Player />
        </Frame2>
      </section>
      <section id='section_5'>
        <Frame2>
          <Footer />
        </Frame2>
      </section>
    </div>
  );
}

export default App;
