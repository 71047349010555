import { useEffect, useMemo, useState } from "react";
import CriminalWeb from '../../assets/slider/Web/Criminal (Deadbeats) [Zeds Dead, Hamdi, Warrior Queen].jpg'
import CriminalMobile from '../../assets/slider/Mobile/Criminal (Deadbeats) [Zeds Dead, Hamdi, Warrior Queen].jpg'
import FlyanaWeb from '../../assets/slider/Web/Flyana Boss - Skateboard (Atlantic Records) [This Ain’t The Album EP].jpg'
import FlyanaMobile from '../../assets/slider/Mobile/Flyana Boss - Skateboard (Atlantic Records) [This Ain’t The Album EP].jpeg'
import ParadiseWeb from '../../assets/slider/Web/Paradise (Saban Music Group) [Mergui].jpg'
import ParadiseMobile from '../../assets/slider/Mobile/Paradise (Saban Music Group) [Mergui].jpeg'
import ValorantWeb from '../../assets/slider/Web/Valorant - Villain (Riot Games) [VCT EMEA 2024 Anthem].jpg'
import ValorantMobile from '../../assets/slider/Mobile/Valorant - Villain (Riot Games) [VCT EMEA 2024 Anthem].png'
import ThreeGirlsMobile from '../../assets/slider/Mobile/Girls.jpg'
import ThreeGirlsWeb from '../../assets/slider/Web/Girls.png'
import './slider.css';
import { useMediaQuery } from "react-responsive";

const list: {
    metadata: { title: string, description: string, link: string },
    image: {
        web: {
            src: string;
            alt: string;
        },
        mobile:
        {
            src: string;
            alt: string;
        }
    }
}[] = [
        {
            metadata: {
                title: 'Criminal (Deadbeats)',
                description: 'Zeds Dead, Hamdi, Warrior Queen',
                link: 'https://youtu.be/eksDHttSWPs?si=O_5sG93d7EjXm_zM'
            },
            image: {
                web: {
                    src: CriminalWeb,
                    alt: 'Criminal (Deadbeats)',
                },
                mobile: {
                    src: CriminalMobile,
                    alt: 'Criminal (Deadbeats)',
                }
            },
        },
        {
            metadata: {
                title: 'Flyana Boss - Skateboard',
                description: 'This Ain’t The Album EP',
                link: 'https://youtu.be/GkRQxQgZYfM?si=4ilHep70d-GRUf6A',

            },
            image: {
                web: {
                    src: FlyanaWeb,
                    alt: 'Flyana Boss - Skateboard (Atlantic Records)',
                },
                mobile: {
                    src: FlyanaMobile,
                    alt: 'Flyana Boss - Skateboard (Atlantic Records)',
                }
            },
        },
        {
            metadata: {
                title: 'Paradise (Saban Music Group)',
                description: 'Mergui',
                link: 'https://youtu.be/81YuEyGy0ic?si=8D-HMR_Zvfxug5IJ',
            },
            image: {
                web: {
                    src: ParadiseWeb,
                    alt: 'Paradise (Saban Music Group)',
                },
                mobile: {
                    src: ParadiseMobile,
                    alt: 'Paradise (Saban Music Group)',
                }
            },
        },
        {
            metadata: {
                title: 'Valorant - Villain (Riot Games)',
                description: 'VCT EMEA 2024 Anthem',
                link: 'https://youtu.be/klt61gLalKQ?si=omyI28ARp7cF25Pz',
            },
            image: {
                web: {
                    src: ValorantWeb,
                    alt: 'Valorant - Villain (Riot Games)',
                },
                mobile: {
                    src: ValorantMobile,
                    alt: 'Valorant - Villain (Riot Games)',
                }
            },
        },
        {
            metadata: {
                title: '3 Girls (Noa Kirel, Gitit Fisher)',
                description: '#1 Spotify - Israel',
                link: 'https://youtu.be/4GtMN9PQPxw?si=pgyd1pPUlhOj_JRd',
            },
            image: {
                web: {
                    src: ThreeGirlsWeb,
                    alt: '3 Girls (Noa Kirel, Gitit Fisher)',
                },
                mobile: {
                    src: ThreeGirlsMobile,
                    alt: '3 Girls (Noa Kirel, Gitit Fisher)',
                }
            },
        },
    ]

export const Slider: React.FunctionComponent = () => {
    const [index, setIndex] = useState(0)

    const isMobile = useMediaQuery({ maxWidth: 768 });

    useEffect(() => {
        const id = setTimeout(() => {
            if (index == list.length - 1) {
                setIndex(() => 0);
            }
            else {
                setIndex(() => index + 1);

            }
        }, 3000);

        return () => {
            clearTimeout(id)
        }
    }, [index]);

    return (
        <ul className="slider_component">
            {list.map((slide, idx) => {
                const { link } = slide.metadata;
                const { src, alt } = isMobile ? slide.image.mobile : slide.image.web;
                return (
                    <>
                        <li
                            className="slide"
                            data-active={String(idx === index)}
                        >
                            <img src={src} alt={alt} />
                            <div  onClick={() => { window.open(link) }} className="slider_component_description">
                                <div className="slider_component_description_content">
                                    <h5>{slide.metadata.title}</h5>
                                    <p>{slide.metadata.description}</p>
                                </div>
                                <div className="slider_component_description_background"></div>
                            </div>
                        </li>
                    </>
                )
            })}
        </ul>
    )
}